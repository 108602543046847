import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "./components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "./components/features/ThreeColWithSideImage.js";
import MainFeature from "./components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "./components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "./components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "./components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "./components/faqs/SingleCol.js";
import GetStarted from "./components/cta/GetStartedLight.js";
import Footer from "./components/footers/FiveColumnWithInputForm.js";

const HighlightedText = tw.span`text-primary-500`

export default (props) => {
  return (
    <AnimationRevealPage>
      <Hero 
        user = {props.user}
      />
      <FeatureStats/>
      <Features 
        heading={<>check those <HighlightedText>Open Source AIs</HighlightedText></>}
        subheading = "Featured AIs"
        description = "Need an AI for generating images, deepfake videos, text sentiment analysis, text to speech, and more? They are here!"
        user = {props.user}
        aAIAgent = {props.aEngine}
      />
      <MainFeature
        heading={<>Quality AIs built by <HighlightedText>Professionals</HighlightedText></>}
      />
      <Pricing 
        heading={<>Flexible Plans</>}
      />
      <Testimonial 
        heading={<>Fantastic <HighlightedText>Reviews</HighlightedText></>}
      />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />

      <GetStarted/>
      <Footer 
        user = {props.user}
        aAIAgent = {props.aAIAgent}
      />
    </AnimationRevealPage>
  );

  /*
        <Blog
        subheading="Blog"
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      />
 
  */
}
