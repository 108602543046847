
import {root, API_ROUTE, API_PUBLICROUTE, srv_postRoute, srv_getRoute, srv_patchRoute, API_PRIVATEROUTE} from "./base";
import axios from 'axios';

const srv_getRoot = () => {
  return root
}

/*
 *    USER
 */

// call this to get User's private info (and also to check the user is logged in)
const srv_getMe = async() => {
  return srv_getRoute(API_PRIVATEROUTE+'me', null);
}

// call this to request an Authentication token
const srv_getLoggedClient = async() => {
  return srv_postRoute(API_PRIVATEROUTE+'me/client/login', null);
}

/* 
 *      IMAGE UPLOAD
 */

const srv_postUploadImage = async(file, _authToken) => {
  if(!file || file===0) {
    return null;
  }
  try{
    const formData = new FormData()
    formData.append('file', file)
    let response=await axios({
        method: 'post',
        url: srv_getRoot()+'/api/v1/private/client/upload',
        data: formData,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer "+_authToken
        },
    });
    return response
  }
  catch(err) {
    throw err;
  }
}

/*
 *    CLIENT
 */

// call this to request an Authentication token
const srv_postLogin = async(obj) => {
  return srv_postRoute(API_PUBLICROUTE+'client/demo', {});
}

// post a request to OSAIS (process this AI)
const srv_postAIEngine = async(_engine, obj, authToken) => {
  return srv_postRoute(API_PRIVATEROUTE+'client/ai/'+_engine, obj, authToken);
}

const srv_getRequestInfo = async(obj, authToken) => {
  return srv_getRoute(API_PRIVATEROUTE+'client/request/'+obj.uid, authToken);
}

const srv_getRequestPublicInfo = async(obj) => {
  return srv_getRoute(API_PUBLICROUTE+'request/'+obj.uid);
}

/*
 *    DAEMONS / AI AGENT / ENGINES
 */

const srv_getAIConfig = async(_engine) => {
  return srv_getRoute(API_PUBLICROUTE+'config/'+_engine);
}

const srv_getAllAIEngines = async() => {
  return srv_getRoute(API_PUBLICROUTE+'engines');
}

const srv_getAIAgent = async(_engine) => {
  return srv_getRoute(API_PUBLICROUTE+'aiagent/'+_engine);
}

const srv_getAvailableDaemons = async() => {
  return srv_getRoute(API_PRIVATEROUTE+'me/daemons/locations', null);
}

const srv_patchKeepAliveDaemonContainer = async(_daemon, _container) => {
  return srv_patchRoute(API_PUBLICROUTE+'daemon/'+_daemon+"/container/"+_container+"/keepalive" , {});
}

const srv_getAvailableAIAgentLocationsForEngine = async(_engine) => {
  return srv_getRoute(API_PRIVATEROUTE+'me/aiagent/'+_engine+'/locations', null);
}

const srv_postStartMyAIAgent = async(_container, _port, _docker_image, _daemon_type) => {
  return srv_postRoute(API_PRIVATEROUTE+'me/daemon/start/aiagent', {
    container: _container,
    port: _port,
    docker_image: _docker_image,
    daemon_type: _daemon_type
  });
}

// get an AI Agent certificate
const srv_getAIAgentCertificates = async(_engine, _thid) => {
  const _url=API_PUBLICROUTE+"/certificate/aiagent/"+_engine+(_thid? _thid : "");
  return srv_getRoute(_url);
}

// get a Daemon certificate
const srv_getDaemonCertificates = async(_engine, _thid) => {
  const _url=API_PUBLICROUTE+"/certificate/daemon/"+_engine+(_thid? _thid : "");
  return srv_getRoute(_url);
}

// get a user certificate
const srv_getUserCertificates = async(_engine, _thid) => {
  const _url=API_PUBLICROUTE+"/certificate/user/"+_engine+(_thid? _thid : "");
  return srv_getRoute(_url);
}

/*
 *    MAILER
 */

// send confirmation email to user
const srv_postContactUs = async(obj) => {
  return srv_postRoute(API_ROUTE+'mailer/contactus', obj);
}

// register a contact detail into Beta prog
const srv_postRegisterBetaProgram = async(obj) => {
  return srv_postRoute(API_ROUTE+'mailer/contact', obj);
}

/*
 *    PUBLIC
 */

// status
const srv_getStatus = async() => {
  return srv_getRoute(API_PUBLICROUTE+'/', {});
}

/*
 *    DEMO LOGIN
 */

// get a demo user Auth
const srv_getDemoLogin = async() => {
  const _url=API_PUBLICROUTE+'user/demo'
  return srv_getRoute(_url, {});
}

export { 
  srv_getRoot,
  srv_getStatus,
  srv_getMe,
  srv_getLoggedClient,
  srv_postLogin,
  srv_postUploadImage,
  srv_postAIEngine,
  srv_getAIConfig,

  srv_getAIAgent,
  srv_getAllAIEngines,
  srv_getAvailableDaemons,
  srv_getAvailableAIAgentLocationsForEngine,
  srv_patchKeepAliveDaemonContainer,
  srv_postStartMyAIAgent,

  srv_getAIAgentCertificates,
  srv_getDaemonCertificates,
  srv_getUserCertificates,

  srv_getRequestInfo,
  srv_getRequestPublicInfo,
  srv_postContactUs,
  srv_postRegisterBetaProgram,

  srv_getDemoLogin

}