import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SnapshotSoloImage} from "components/ai/aiSnapshot.js";
import {WidgetAIAvailability} from "components/ai/aiAvailability.js";
import { ReactComponent as ShieldIcon } from "feather-icons/dist/icons/shield.svg";

/* css */
const ContainerWithBorder = tw.div `sm:rounded-lg  `;
const Content = tw.div`max-w-screen-xl bg-white text-gray-900 shadow-none sm:shadow flex justify-center flex-1 max-md:block  `;
const FirstContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-2 lg:w-2/3`;
const SecondContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-2 lg:w-1/3`;
const MainContent = tw.div`mt-2 flex flex-col  `;
const FeatureList = tw.ul`mt-2 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const FeatureProp = tw.p`ml-2 font-medium text-gray-900 font-bold`;
const FeatureTextSmaller = tw.p`ml-2 font-normal text-gray-700 text-sm`;
const FeaturePropSmaller = tw.p`ml-2 font-normal text-gray-900 font-bold text-sm`;
const LineBreak = tw.p`mt-4`;
const FSIcon = tw(ShieldIcon)`w-5 h-5 text-green-900 inline mr-2`;

export const SectionAIInfo = (props) => {

 /* 
*   UI renders
*/
  const _getShortString = (_u) => {
    if(!_u) {return ""}
    if (_u.length<=11) {return _u}
    return _u.substring(0, 6) + "..."+ _u.substring(_u.length-6, _u.length)
  }
  const _getValueFromProp = (obj, prop) => {
    if(!obj) {
      return "*****"
    }
    if(obj[prop]===null || obj[prop]===undefined) {
      return "unknown"
    }
    return obj[prop];
  }

  const  _getCentFromProp = (obj, prop) => {
    if(!obj || obj[prop]===null || obj[prop]===undefined) {return 0}
    return (Math.ceil (1000 * obj[prop]/100)/1000 ) +" $Cent";
  }

  return (
    <ContainerWithBorder>
    {props.isVisible? 
        <Content>
          <FirstContainer >
            <MainContent>
            <FeatureList>
                <Feature key={"descr"}>
                  <FeatureText>{_getValueFromProp(props.ai_config, "name")}</FeatureText>
                  :
                  <FeatureText>{_getValueFromProp(props.ai_config, "description")}</FeatureText>
                </Feature>

                <LineBreak />

                  <WidgetAIAvailability 
                    ai_config= {props.ai_config}
                    ai_agent= {props.ai_agent}
                    user= {props.user}
                    onAlert = {props.onAlert}
                    reloadAIAgents={props.reloadAIAgents}
                  />

                  <LineBreak />

                  {props.ai_agent && props.ai_agent.aLocation.length==0?
                  <Feature key={"warmup_time"}>
                    <FeatureProp>Warmup time:</FeatureProp>
                    <FeatureText>{_getValueFromProp(props.ai_config, "expected_warmup_in_sec")} seconds</FeatureText>
                  </Feature>
                  :""}
                  <Feature key={"tx_duration"}>
                    <FeatureProp>Transaction duration:</FeatureProp>
                    <FeatureText>{_getValueFromProp(props.ai_config, "expected_tx_in_ms")} millisecs</FeatureText>
                  </Feature>

                  <Feature key={"tx_cost"}>
                    <FeatureProp>Transaction cost:</FeatureProp>
                    <FeatureText>{_getValueFromProp(props.ai_config, "isFree")===true? "Free": _getCentFromProp(props.ai_agent, "expected_tx_in_usd")}</FeatureText>
                  </Feature>

                  <LineBreak />

                  {props.ai_agent && props.ai_agent.did ?
                  <>
                    <Feature key={"did"}>
                      <FeaturePropSmaller>Digital Identity:</FeaturePropSmaller>
                      <FeatureTextSmaller>{_getShortString(_getValueFromProp(props.ai_agent.did, "didAuth"))}</FeatureTextSmaller>
                    </Feature>

                    <Feature key={"wallet"}>
                      <FeaturePropSmaller>AI Identity Wallet:</FeaturePropSmaller>
                      <FeatureTextSmaller>{_getShortString(_getValueFromProp(props.ai_agent.did, "public_addr"))}</FeatureTextSmaller>
                    </Feature>

                    {props.ai_agent.did.identity_thid? 
                    <Feature key={"proof_id"}>                      
                      <FeaturePropSmaller><FSIcon />Proof of Identity:</FeaturePropSmaller>
                      <FeatureTextSmaller><a href={"/WorldOfAIs/ai/ping/proof/?thid="+props.ai_agent.did.identity_thid}>access verified proof...</a></FeatureTextSmaller>
                    </Feature>
                    :""}

                    <LineBreak />

                  </>
                  :""}

                  <Feature key={"github"}>
                    <FeaturePropSmaller>GitHub repo:</FeaturePropSmaller>
                    <FeatureTextSmaller><a href={_getValueFromProp(props.ai_config, "github")} target="_blank">{_getValueFromProp(props.ai_config, "github")}</a></FeatureTextSmaller>
                  </Feature>
                  <Feature key={"docker"}>
                    <FeaturePropSmaller>Docker image:</FeaturePropSmaller>
                    <FeatureTextSmaller>{_getValueFromProp(props.ai_config, "docker_image")}</FeatureTextSmaller>
                  </Feature>
                  <Feature key={"gpu"}>
                    <FeaturePropSmaller>Requires GPU?:</FeaturePropSmaller>
                    <FeatureTextSmaller>{_getValueFromProp(props.ai_config, "requiresGPU")? "yes": "no"}</FeatureTextSmaller>
                  </Feature>
                  <Feature key={"conf_ver"}>
                    <FeaturePropSmaller>Config version:</FeaturePropSmaller>
                    <FeatureTextSmaller>{_getValueFromProp(props.ai_config, "config_version")}</FeatureTextSmaller>
                  </Feature>
                  <Feature key={"agent_ver"}>
                    <FeaturePropSmaller>Agent version:</FeaturePropSmaller>
                    <FeatureTextSmaller>{_getValueFromProp(props.ai_agent, "agent_version")}</FeatureTextSmaller>
                  </Feature>
              </FeatureList>
            </MainContent>
          </FirstContainer>

          <SecondContainer >
              <SnapshotSoloImage 
                engine = {props.ai_engine}
              />
          </SecondContainer>

        </Content>     
    :""}
    </ContainerWithBorder>
  );
}