import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as SendIcon } from "feather-icons/dist/icons/send.svg";
import { srv_postUploadImage } from "services/osais";

import { UiFileSelect } from './ui/uiSelectFile'; 
import { UiMultiToggle } from './ui/uiMultiToggle'; 
import { UiSwitch } from './ui/uiSwitch'; 
import { UiSlider } from './ui/uiSlider'; 
import { UiEdit } from './ui/uiEdit'; 
import { UiSelect } from './ui/uiSelect'; 

import {WidgetAIAvailability} from "components/ai/aiAvailability.js";

import {Section, SectionTitle, DisabledStyle} from "./ui/uiStyles";
import {FILETYPE_UNKNOWN, FILETYPE_IMAGE, FILETYPE_MOVIE, FILETYPE_VOICE} from './ui/uiConst'; 
import {TYPE_ALERT_CRITICAL, TYPE_ALERT_WARNING, TYPE_ALERT_INFO} from "./myAlert";

/* css */

const SmallPrint=styled.p `${tw` mt-6 text-xs text-gray-600 text-center` }`;
const SmallPrintLargerRed=styled.p `${tw`mt-8 text-sm text-red-600 text-center px-4 py-8 border-dashed border-2 font-bold rounded border-red-300  bg-[#fff2f2]` }`;
const SmallPrintLink=styled.a `${tw`border-b border-gray-500 border-dotted` }`;

const Form = tw.form`mx-auto max-w-xs`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const ContainerWithBorder = tw.div `sm:rounded-lg  `;
const Content = tw.div`max-w-screen-xl bg-white text-gray-900 shadow-none sm:shadow flex justify-center flex-1 max-md:block  `;
const MainContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-6 lg:w-1/2 xl:w-5/12   `;
const MainContent = tw.div`mt-2 flex flex-col  `;
const FormContainer = tw.div`w-full mt-2`;

export const SectionPrivateInput = (props) => {

  // generic UI
  const [isReady, setIsReady] = useState(false);

  // ai UI input params
  const [state, setState] = useState({config: []});      // all UI elements taken from the config


  useEffect(() => {
      if(state.config.length===0 && props.ai_config!==null) {
        _async_initEngine(props.ai_config)
        .then(_config => {
          setIsReady(_isReady(_config));
        })
      }
  },[props.ai_config])

  useEffect(() => {
    // calc readyness...
    setIsReady(_isReady(state));
  },[state])

  const _isReady = (_config)=> {
      // calc readyness...
      let _isReady=true;
      _config.config.forEach(item => {
        if(item.isMandatory && item.value==null) {
          _isReady=false;
        }
      });
      return _isReady;
  }

  const _async_initEngine = async (_objConf) => {
    if(_objConf) {

      try{

        // let s deal with those UI params
        let _aParam=[];
        for (var i=0; i<_objConf.aParam.length; i++) {
          _aParam.push(_objConf.aParam[i]);
          if(_aParam[i].hasOwnProperty("ui")) {

            switch (_aParam[i]["ui"]["widget"]) {

              case "uiMultiToggle":
                _aParam[i]=await async_initializeMultiToggleState(_aParam[i]);
                break;

              case "uiSwitch":
                _aParam[i]=await async_initializeState(_aParam[i]);
                break;

              case "uiRangeSlider":
                _aParam[i]=await async_initializeSliderState(_aParam[i]);
                  break;

              case "uiEdit":
                _aParam[i]=await async_initializeState(_aParam[i]);
                  break;

              case "uiSelect":
                _aParam[i]=await async_initializeSelectState(_aParam[i]);
                  break;

                  case "uiSelectFile":
              case "uiSelectPicture":
                _aParam[i]=await async_initializeState(_aParam[i]);
                break;

              default:
                break;                 
            }

            // conditional widget??
            if(_aParam[i]["ui"].hasOwnProperty("conditional")) {
              if(_aParam[i]["ui"]["conditional"].hasOwnProperty("$null")) {
                let _propIfNull=_aParam[i]["ui"]["conditional"]["$null"];
                _aParam[i]["ui"]["fnConditional"]=function(){
                  let j=_aParam.findIndex(function (x) {return (x.in===_propIfNull)});
                  return _aParam[j]["value"]["current"]!=null;
                }
              }
              if(_aParam[i]["ui"]["conditional"].hasOwnProperty("$exist")) {
                let _propExist=_aParam[i]["ui"]["conditional"]["$exist"];
                _aParam[i]["ui"]["fnConditional"]=function(){
                  let j=_aParam.findIndex(function (x) {return (x.in===_propExist)});
                  return _aParam[j]["value"]["current"]==null;
                }
              }
            }
          }
          // no UI but mandatory? set the value from default
          else {
            if(_aParam[i].isMandatory===true) {
              _aParam[i]=await async_initializeState(_aParam[i]);
            }
          }
        }

        // another pass for init all linked widgets
        _aParam.forEach(async(item) => {
            try {
              // has linked widgets?
              if(item["ui"] && item["ui"]["autofill"] && item["ui"]["autofill"].length>0) {
                item["ui"]["autofill"].forEach(async(linked) => {
                  try {
                    let j=_aParam.findIndex(function (x) {return (x.in===linked.in)});
                    if(j!==-1) {
                      _aParam[j].value.default=item.value.default;
                      _aParam[j]=await async_initializeState(_aParam[j]);  
                    }
                  }
                  catch(err) {throw err}
                })
              }
            }
            catch(err) {}
        })

        setState({config: _aParam});
        return {config: _aParam}
      }
      catch(err) {
        throw err;
      }
    }
    return {config: []};
  }

/* 
 *    dealing with generic UI (from config to UI)
 */  

  // default UI fcts
  const async_initializeState= async (objParam) => {
    // set default current value
    let _default=(objParam.value && objParam.value.default!==null && objParam.value.default!==undefined)? objParam.value.default : null;
    objParam.value.current=_default;
    return objParam;
  }

  const async_setValue= async (_property, _value) => {
    // get the UI object from property
    let _aTmp=state.config;
    let i=_aTmp.findIndex(function (x) {return (x.in===_property)});
    if(i===-1) {
        return;
    }

    // set new value
    if(_aTmp[i].hasOwnProperty("percent")) {
    _aTmp[i]["percent"]=_value;
    _aTmp[i]["value"]["current"]=_value * _aTmp[i]["value"]["max"] / 100;
    }
    else {
    _aTmp[i]["value"]["current"]=_value;
    }

    // must wait for state to be set before doing another one
    await setState({config: _aTmp});

    // is this a linked UI? => autofill another prop
    if(_aTmp[i]["ui"] && _aTmp[i]["ui"]["autofill"]) {
      _aTmp[i]["ui"]["autofill"].forEach(async (item) => {
        try {
          await async_setValue(item.in, _value);
        }
        catch(err) {}
      });
    }  

    // return the modified object
    return _aTmp[i];
  }

  // accepted input from list of input
  const _async_getAcceptedInputInRange = async(objAccept) => {
    let _aOption=[];
    for (var o=0; o<objAccept["display"].length; o++){
      let _intVal=parseInt(objAccept["set"][o]);
      let isDisabled = (objAccept["set"]===null)
      _aOption.push({
        text: objAccept["display"][o],
        value: objAccept["set"][o],
        isDisabled: isDisabled      // todo? we do not disable here... since we have a full set of choices (freeAccess is a smaller subset of choice, excludes the "disabled")
      })
    }
    return _aOption; 
  }

  // case of MultiToggle
  const async_initializeMultiToggleState = async(objParam) => {

    // demo user? limit the set of values
    if (props.user===null && objParam["freeAccess"] ) {
      let _set=(objParam["freeAccess"]["set"]!==null && objParam["freeAccess"]["set"]!==undefined)? objParam["freeAccess"]["set"] : null;
      let _display=(objParam["freeAccess"]["display"]!==null && objParam["freeAccess"]["display"]!==undefined)? objParam["freeAccess"]["display"] : null;
      let _val=(objParam["freeAccess"]["default"]!==null && objParam["freeAccess"]["default"]!==undefined)? objParam["freeAccess"]["default"] : null;
      if(_set!==null) {objParam["value"]["set"] = _set}; 
      if(_display!==null) {objParam["value"]["display"] = _display}; 
      if(_val!==null) {objParam["value"]["default"] = _val}; 
    }

    objParam["ui"]["options"] = await _async_getAcceptedInputInRange(objParam["value"]); 
    return async_initializeState(objParam);
  }

  // case of Select
  const async_initializeSelectState = async(objParam) => {

    // demo user? limit the set of values
    if (props.user===null && objParam["freeAccess"] ) {
      let _set=(objParam["freeAccess"]["set"]!==null && objParam["freeAccess"]["set"]!==undefined)? objParam["freeAccess"]["set"] : null;
      let _display=(objParam["freeAccess"]["display"]!==null && objParam["freeAccess"]["display"]!==undefined)? objParam["freeAccess"]["display"] : null;
      let _val=(objParam["freeAccess"]["default"]!==null && objParam["freeAccess"]["default"]!==undefined)? objParam["freeAccess"]["default"] : null;
      if(_set!==null) {objParam["value"]["set"] = _set}; 
      if(_display!==null) {objParam["value"]["display"] = _display}; 
      if(_val!==null) {objParam["value"]["default"] = _val}; 
    }

    objParam["ui"]["options"] = await _async_getAcceptedInputInRange(objParam["value"]); 
    return async_initializeState(objParam);
  }

  // case of RangeSlider
  const async_initializeSliderState = async(objParam) => {
    if (props.user===null && objParam["freeAccess"]) {
      let _max=(objParam["freeAccess"]["max"]!==null && objParam["freeAccess"]["max"]!==undefined)? objParam["freeAccess"]["max"] : null;
      let _min=(objParam["freeAccess"]["min"]!==null && objParam["freeAccess"]["min"]!==undefined)? objParam["freeAccess"]["min"] : null;
      let _val=(objParam["freeAccess"]["default"]!==null && objParam["freeAccess"]["default"]!==undefined)? objParam["freeAccess"]["default"] : null;
      if(_max!==null) {objParam["value"]["max"] = _max}; 
      if(_min!==null) {objParam["value"]["min"] = _min}; 
      if(_val!==null) {objParam["value"]["default"] = _val}; 
    }
    if(objParam["ui"]["displayAsPercent"] && objParam["ui"]["displayAsPercent"]===true ) {
      objParam["percent"] = 100*objParam["value"]["default"] / objParam["value"]["max"];
    }
    return async_initializeState(objParam);
  }

  // case of SelectFile ; check validity + does this file require a crop?
  const validateFile= (_property, _props) => {
    if(!_props) {
      return {
        isValid: false
      }
    }
    let _aTmp=state.config;
    let i=_aTmp.findIndex(function (x) {return (x.in===_property)});
    if(i===-1) {
      return {
        isValid: false
      }
    }

    // any type except image, we can return now
    let _isTooBig=(_props.kb > getMaxSize(_aTmp[i]));
    if(_props.type!==FILETYPE_IMAGE) {
      return {
        isValid: !_isTooBig,
        isTooBig: _isTooBig,
      }
    }

    // image... is this in ratio? (a -1 ratio means all are accepted)
    let _requiredRatio = (_aTmp[i].ui && _aTmp[i].ui.hasOwnProperty("ratio")) ? parseInt(_aTmp[i].ui.ratio) : 1;
    let _isFitRatio = _requiredRatio===-1? true: (_props.h>0)? (_props.w/_props.h === _requiredRatio) : false;
    return {
      isValid: !_isTooBig,
      isTooBig: _isTooBig,
      isFitRatio: _isFitRatio
    }; 
  };

/* 
 *    utilities   
 */  

  // return max alowed size of item
  const getMaxSize = (item) => {
    let maxSize=(item.ui && item.ui.hasOwnProperty("maxKb")) ? item.ui.maxKb : 1024;
    return maxSize;
  }

  const getAcceptedFormats = (item) => {
    let aFilter=(item.ui && item.ui.hasOwnProperty("filterExt")) ? item.ui.filterExt : [];
    return aFilter;
  }

  const getFileType = (aExt) => {
    if(aExt) {
      if(aExt[0]===".png" || aExt[0]===".jpg" || aExt[0]===".jpeg") {
        return FILETYPE_IMAGE;
      }
      if(aExt[0]===".wav" || aExt[0]===".mp3" ) {
        return FILETYPE_VOICE;
      }
      if(aExt[0]===".mp4" ) {
        return FILETYPE_MOVIE;
      }
    }
    return FILETYPE_UNKNOWN;
  }


  const getObjectSize = ( ) => {
    let _aTmp=state.config;
    let iW=_aTmp.findIndex(function (x) {return (x.in==="width")});
    let iH=_aTmp.findIndex(function (x) {return (x.in==="height")});
    if(iW!==-1 && iH!==-1) {
      return {
        width: _aTmp[iW].value,
        height: _aTmp[iH].value,
      }
    }
    return null;
  }
  
/* 
*   UI renders
*/

  const renderParamUI = function(item, index, nColumn) {
    if(item && item.hasOwnProperty("ui")) {

      // display none?
      if(item.ui.display===false) {return (<></>)}

      // conditional?
      let _isCond=(item.ui.fnConditional)? item.ui.fnConditional() : false;
      if(_isCond) {return (<></>)}

      // col 1 or 2?
      let _isCol=((nColumn===2 && item.ui.column && item.ui.column===2) || 
        (nColumn===1 && !item.ui.hasOwnProperty("column")) || 
        (nColumn===1 && item.ui.column===1));

      if(!_isCol) {return (<></>)}

      switch (item.ui.widget) {

        case "uiEdit":
          return (
              <UiEdit 
                isMobile = {props.isMobile}
                title={item.ui.title}
                placeholder={item.ui.placeholder}
                value={item.value.current? item.value.current :""} 
                onAlert = {(_obj) => props.onAlert? props.onAlert(_obj): {}}
                onChange={(_event) => {
                    async_setValue(item.in, _event.target.value) 
                  }
                }
              />);

        case "uiMultiToggle":
          return (
              <UiMultiToggle 
                isMobile = {props.isMobile}
                title= {item.ui.title}
                aOption= {item.ui.options}
                selected={item.value.current}
                onAlert = {(_obj) => props.onAlert? props.onAlert(_obj): {}}
                onChange={(_value) => {
                      async_setValue(item.in, _value);
                    }
                }
              />);

        case "uiSwitch":
          return (
              <UiSwitch 
                  isMobile = {props.isMobile}
                  title= {item.ui.title}
                  selected={item.value.current}
                  disabled={false}
                  onAlert = {(_obj) => props.onAlert? props.onAlert(_obj): {}}
                  onChange={(_value) => {
                      async_setValue(item.in, _value);
                    }
                  }
              />);
      
        case "uiSelect":
          return (
              <UiSelect 
                  isMobile = {props.isMobile}
                  title= {item.ui.title}
                  selected={item.value.current}
                  aOption={item.ui.options}
                  disabled={false}
                  onAlert = {(_obj) => props.onAlert? props.onAlert(_obj): {}}
                  onChange={(_value) => {
                      async_setValue(item.in, _value);
                    }
                  }
              />);
            
        case "uiRangeSlider": 
          return (
              <UiSlider
                isMobile = {props.isMobile}
                title= {item.ui.title}
                tootip= {item.ui.tooltip}
                minValue= {item.percent? 0 : item.value.min}
                maxValue= {item.percent? 100 : item.value.max}
                unit= {item.ui["displayAs%"]? "%" : (item.ui.unit? item.ui.unit: "")}
                curValue= {item.percent? item.percent : item.value.current}
                onAlert = {(_obj) => props.onAlert? props.onAlert(_obj): {}}
                onChange= {(_event) => {
                  async_setValue(item.in, _event.target.value);
                }
              }
              />);

        case "uiSelectFile":
        case "uiSelectPicture":

          let objSize=getObjectSize();
          let maxSize=getMaxSize(item);
          let aExt=getAcceptedFormats(item);
          let _type=getFileType(aExt);
          return (
              <UiFileSelect 
                  user = {props.user}
                  isMobile = {props.isMobile}
                  title = {item.ui.title}
                  id = {index}
                  isHidden={false}
                  assetDir={"../assets"}
                  maxFileSize= {maxSize}
                  aExt={aExt}
                  fileType={_type}
                  onAlert = {(_obj) => props.onAlert? props.onAlert(_obj): {}}
                  onFileReady={async (_props) => {
                    try {
                      let objUpload=await srv_postUploadImage(_props.selectedFile, props.authToken)
                      if(objUpload && objUpload.data && objUpload.data.data && objUpload.data.data.s3_uri) {
                        await async_setValue(item.in, objUpload.data.data.s3_uri);
                      }
                      else {
                        await async_setValue(item.in, _props.srcFile);
                      }
  
                      // any reset fct to call?
                      if(_props.fnReset) {
                        props.onPreReset(_props.fnReset);
                      }  
                      return objUpload;
                    }
                    catch(err) {
                      throw err;
                    }
                  }}
                  onS3Selected={async (_s3) => {
                    // user selected a profile picture
                    await async_setValue(item.in, _s3);
                  }}
                  validateFile={(_props) => {
                      return validateFile(item.in, _props);
                  }}                
            />
          );
  
        default:
          return null;
      }
    }
    return null;
  }

  const renderSubmitButton = ( ) => {
    return (         
      <>
        <SubmitButton 
          type="submit"
          style = {isReady ? {}: DisabledStyle}
        >
          <SendIcon className="icon" />
          <span className="text">Generate!</span>
        </SubmitButton>
        
        <SmallPrint>
          This will generate an image based on your input parameters. Use our APIs for production needs.  
        </SmallPrint>

        {props.isUserAccessGranted? 
          ""
        : 
          <SmallPrintLargerRed >
            You are using a Demo account
            <br />
            <SmallPrintLink href="/app">
              Sign In 
            </SmallPrintLink>
            <span> to avoid limitations</span>
          </SmallPrintLargerRed>
      }
      </>            
    )
  }

  return (
    <ContainerWithBorder>
    {props.isVisible? 

        <Content>
          <MainContainer >

            <MainContent>
            {state.config.map((item, index) => (
                <div 
                  key = {1000 + index}
                >
                  {renderParamUI(item, index, 1)}
                </div>              
            ))}
            </MainContent>
          </MainContainer>

          <MainContainer >
            <MainContent>
              {state.config.map((item, index) => (
                <div 
                  key = {2000 + index}
                >
                  {renderParamUI(item, index, 2)}
                </div>
              ))}

          <FormContainer
                  key = {5000}
          >               

            <Form  onSubmit={(evt)=> {
                    if(evt) {
                      evt.preventDefault();
                    }
                    props.onSubmit(state, false);
                  }
                }>
                  
                {renderSubmitButton( )}
                
            </Form>
          </FormContainer>

            </MainContent>
          </MainContainer>

        </Content>     
    :""}
    </ContainerWithBorder>
  );
}