
import React, { useState, useEffect } from "react";
import GlobalStyles from 'styles/GlobalStyles';

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
import LoginPage from "pages/Login.js";
import DemoLoginPage from "pages/DemoLogin.js";
import AiPage from "pages/aiPage.js";
import ErrorPage from "pages/Error.js";
import LogoutPage from "pages/Logout.js";
import PricingPage from "pages/Pricing.js";
import ListAIPage from "pages/aiList.js";
import BlogPage from "pages/BlogIndex.js";
import ArticlePage from "pages/ArticlePage.js";

// import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

//import ComponentRenderer from "ComponentRenderer.js";
import HomePage from "./HomePage.js";
//import ThankYouPage from "ThankYouPage.js";

import {srv_getStatus, srv_getMe, srv_getAllAIEngines} from "services/osais";
import {srv_getArticles, srv_getAuthors, srv_getCategories } from "services/sanity";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';

let _DidMount=false;
export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  const [me, setMe] = useState(false); 
  const [aEngine, setaEngine] = useState([]); 

  // blog
  const [aArticle, setAArticle] = useState([]); 
  const [aAuthor, setAAuthor] = useState([]); 
  const [aCategory, setACategory] = useState([]); 
  const [blogger, setBlogger] = useState(null); 

  // ui
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    if(!_DidMount) {
      _DidMount=true;

      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        window.screen.width < 425
      ) {
        setIsMobile(true);
      } 

      // get user details
      srv_getMe()
        .then(dataMe=> {
          setMe(dataMe.data);
        })
        .catch(err => {
          setMe(null);
        });

      srv_getAllAIEngines()
        .then(dataEng=> {
          setaEngine(dataEng.data);
        })
        .catch(err => {

        });

      // get blogs...
      srv_getStatus()
        .then(res => {

          if(res.data && res.data.blogger) {
            setBlogger(res.data.blogger);
          }

          srv_getAuthors() 
            .then(res => {
              if(res && res.data && res.data.length>0) {
                  setAAuthor(res.data);
              }

              srv_getCategories() 
              .then(res => {
                if(res && res.data && res.data.length>0) {
                    setACategory(res.data);
                }

                srv_getArticles() 
                .then(res => {
                  if(res && res.data && res.data.length>0) {
                    setAArticle(res.data);
                  }
                })
                .catch(err => {});
        
              })
              .catch(err => {});
      
            })
            .catch(err => {});
        })
        .catch(err => {});
    }
})

return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>

      {/* Various marketing section */}
          <Route path="/" element={<HomePage 
            isMobile = {isMobile}
            user = {me}
            aEngine = {aEngine}
          />} />
          <Route path="/WorldOfAIs/logout" element={<LogoutPage 
            user = {me}
          />} />

          <Route path="/WorldOfAIs/pricing" element={<PricingPage 
            isMobile = {isMobile}
            user = {me}
            aEngine = {aEngine}
          />} />
          <Route path="/WorldOfAIs/list" element={<ListAIPage 
            isMobile = {isMobile}
            user = {me}
            aEngine = {aEngine}
          />} />
          <Route path="/WorldOfAIs/contactus" element={<ContactUsPage 
            isMobile = {isMobile}
            user = {me}
            aEngine = {aEngine}
          />} />

      {/* Legals section */}

          <Route path="/WorldOfAIs/privacy" element={<PrivacyPolicyPage 
            isMobile = {isMobile}
            user = {me}
          />} />
          <Route path="/WorldOfAIs/terms" element={<TermsOfServicePage 
            isMobile = {isMobile}
            user = {me}
          />} />

      {/* Authentication section */}

          <Route path="/WorldOfAIs" element={<LoginPage 
            isMobile = {isMobile}
            headline="The world of Open Source AIs"
            subHeadline="Sign-up via Social Media"
          />} />
          <Route path="/WorldOfAIs/signup" element={<LoginPage 
            isMobile = {isMobile}
            headline="The world of Open Source AIs"
            subHeadline="Sign-up via Social Media"
          />} />
          <Route path="/WorldOfAIs/login" element={<LoginPage 
            isMobile = {isMobile}
            headline="Welcome back"
            subHeadline="Sign-in via Social Media"
          />} />
          <Route path="/WorldOfAIs/demologin" element={<DemoLoginPage 
            isMobile = {isMobile}
            headline="Demo login..."
            subHeadline="Sign-in as Demo user"
          />} />

      {/* AI section */}

          <Route 
            path="/WorldOfAIs/ai/:engine"
            element={<AiPage 
              isMobile = {isMobile}
              user = {me}
              aEngine = {aEngine}
              panel = "run"
            />} 
          />

          <Route 
            path="/WorldOfAIs/ai/:engine/info"
            element={<AiPage 
              isMobile = {isMobile}
              user = {me}
              aEngine = {aEngine}
              panel = "info"
            />} 
          />

          <Route 
            path="/WorldOfAIs/ai/:engine/proof"
            element={<AiPage 
              isMobile = {isMobile}
              user = {me}
              aEngine = {aEngine}
              panel = "proof"
            />} 
          />

          <Route 
            path={"/WorldOfAIs/public/request/:request"} 
            element={<AiPage 
              user = {me}
              isPublic = {true}
            />} 
          />

      {/* Blog section */}
          <Route 
            path="/blog" 
            element={<BlogPage 
              isMobile = {isMobile}
              user = {me}
              aArticle = {aArticle}
              aCategories = {aCategory}
              aEngine = {aEngine}
            />} 
          />

          <Route 
            path="/article/:path" 
            element={<ArticlePage 
              blogger = {blogger}
              aArticle= {aArticle}
              aCategory= {aCategory}
              aAuthor = {aAuthor} 
              user = {me}
            />} 
          />

      {/* error */}
          <Route path="/WorldOfAIs/cracked" element={<ErrorPage 
            isMobile = {isMobile}
            user = {me}
            aEngine = {aEngine}
          />} />

          <Route 
            path="*" 
            element={<Navigate to="/WorldOfAIs/cracked" replace />} 
          />

        </Routes>
      </Router>
    </>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
