import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import tw from "twin.macro";
import styled from "styled-components";
import {SnapshotSoloImage} from "components/ai/aiSnapshot.js";
import {WidgetAIAvailability} from "components/ai/aiAvailability.js";
import { ReactComponent as ShieldIcon } from "feather-icons/dist/icons/shield.svg";
import {srv_getAIAgentCertificates} from "services/osais";

/* css */
const ContainerWithBorder = tw.div `sm:rounded-lg  `;
const Content = tw.div`max-w-screen-xl bg-white text-gray-900 shadow-none sm:shadow flex justify-center flex-1 max-md:block  `;
const FirstContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-2 lg:w-2/3`;
const SecondContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-2 lg:w-1/3`;
const MainContent = tw.div`mt-2 flex flex-col  `;
const FeatureList = tw.ul`mt-2 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const FeatureProp = tw.p`ml-2 font-medium text-gray-900 font-bold`;
const FeatureTextSmaller = tw.p`ml-2 font-normal text-gray-700 text-sm`;
const FeaturePropSmaller = tw.p`ml-2 font-normal text-gray-900 font-bold text-sm`;
const LineBreak = tw.p`mt-4`;
const FSIcon = tw(ShieldIcon)`w-5 h-5 text-green-900 inline mr-2`;


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const SectionAIProofs = (props) => {
  const [aCertificate, setACertificate] = useState([]);
  const [schemas, setSchemas] = useState({});

  const query = useQuery();
  const thid = query.get('thid'); 

  useEffect(() => {
    if(props.ai_config  && aCertificate.length==0) {
      srv_getAIAgentCertificates(props.ai_config.engine, thid)
      .then(_data => {
        if(_data.data) {
          setACertificate(_data.data.certificates);
          setSchemas(_data.data.schemas);
        }
      })
    }
},[])

  
/* 
*   UI renders
*/

  return (
    <ContainerWithBorder>
    {props.isVisible? 
        <Content>
            <MainContent>
            <FeatureList>

                <LineBreak />

                  {props.ai_agent && props.ai_agent.did ?
                  <>
                  {aCertificate.map((item, index) => {

                    return (
                      <div key = {index}>
                        <Feature key={"descr"}>
                          <FeatureText><FSIcon /> Type of Certificate</FeatureText>
                          :
                          <FeatureText>{item.claims.claim_type}</FeatureText>
                        </Feature>

                        {
                          Object.entries(item.claims)
                          .filter(([key, value]) => key !== "claim_type")
                          .map(([key, value]) => {
                            let i=schemas[item.claims.claim_type].findIndex(function (x) {return (x.prop===key)});
                            if(i==-1) {return <></>}
                            return (<Feature key={key}>
                              <FeaturePropSmaller>{schemas[item.claims.claim_type][i].display}:</FeaturePropSmaller>
                              <FeatureTextSmaller>{schemas[item.claims.claim_type][i].type=="date"? Date(value): value}</FeatureTextSmaller>

                            </Feature>)
                          })
                        }
                        <LineBreak />

                    </div>)
                    })
                  }

                  </>
                  :""}
                  <LineBreak />
              </FeatureList>
            </MainContent>

        </Content>     
    :""}
    </ContainerWithBorder>
  );
}