import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {srv_getDemoLogin } from "services/osais";

let wasCalled=false;
export default (props) => {

  const navigate = useNavigate();

  if(!wasCalled) {
    wasCalled=true;
    srv_getDemoLogin()
    .then(data => {
      setTimeout(() => {
        window.location.href = '/dashboard/home';         
      }, 1000);
    })
  }

  return (
  <div>
    DEMO LOGIN...

    <br />
    <br />
    You will be redirected to the dashboard as a demo user. If not, click the link below.
    <br />

    <a href ='/dashboard/home'>click me to access dashboard...</a>
  </div>
)};
